<template>
  <div class="g-section myzone-section">
    <div class="g-wrap myzone">
      <div class="myzone-module">
        <span class="module-title">数据统计</span>
        <div class="flex-h-sp">
          <span class="module-box">已投职位（66）</span>
          <span class="module-box">谁看过我（88）</span>
          <span class="module-box">我的信息（999）</span>
          <span class="module-box">简历管理</span>
        </div>
      </div>
      <div class="myzone-module">
        <span class="module-title">账号信息</span>
        <div class="flex-column">
          <span>账号</span>
          <span>头像</span>
          <span>修改密码</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.myzone-section {
  background-color: #f6f6f8;
}
.myzone {
  display: flex;
  flex-direction: column;
}
.myzone-module{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 200px;
  margin-top: 20px;
}
.module-box{
  width: 24%;
  border:1px solid #409EFF;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>